<template>
    <div style="margin-top: 80px;">
        <BasePageBreadCrumb :pages="pages" title="nova página/gatilho" :menu="menu"/>
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12">
                <div class="card card-bg-trans">
                    <div class="card-body pt-4 px-0">
                        <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                            <div class="form-body">
                                <div class="row"> 
                                    <div class="col-12 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Posição</label>
                                            <select class="custom-select mr-sm-2" v-model="trigger.order">
                                                <option :value="null">Selecione</option>
                                                <option :value="1">01</option>
                                                <option :value="2">02</option>
                                                <option :value="3">03</option>
                                                <option :value="4">04</option>
                                                <option :value="5">05</option>
                                                <option :value="6">06</option>
                                                <option :value="7">07</option>
                                                <option :value="8">08</option>
                                                <option :value="9">09</option>
                                                <option :value="10">10</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label>Nome da página/gatilho</label>
                                            <input type="text" id="inputSuccess1" class="form-control" placeholder="Ex.: Selecione o tipo de bancada" v-model="trigger.name">
                                            <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o nome do kit.</div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-12 col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label>Legenda da página</label>
                                            <textarea class="form-control" rows="2" placeholder="" v-model="trigger.subtitle"></textarea>
                                        </div>
                                    </div>
    
                                    <div class="col-12 col-md-12 col-lg-12">
                                        <BaseTipologyBox v-model="trigger.tipologies" :multiple="true" />
                                    </div>
    
                                        
                                    <div class="col-12 col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <BasePersonalizationTypeBox :personalization_type_selected="personalization_type_selected" @input="getSelectedPersonalizationType" @all_personalization_types="getAllPersonalizationTypes" v-model="personalization_type" :multiple="false" />                                     
                                        </div>
                                    </div>
                          
    
                                    <div class="col-12 col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label>Nome do gatilho</label>
                                            <input type="text" id="inputSuccess1" class="form-control" placeholder="Ex.: Selecione o tipo de pedra" v-model="personalization_type_item_name">
                                            <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o nome do kit.</div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-12 col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label>Legenda</label>
                                            <textarea class="form-control" rows="2" placeholder="" v-model="personalization_type_item_subtitle"></textarea>
                                        </div>
                                    </div>
    
                                    <div class="col-12 pb-2">
                                        <label>Selecione os itens do tipo</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text" id="btnGroupAddon2"><i class="fas fa-search"></i></div>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Procurar" v-model="search">
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div data-spy="scroll" data-offset="0" class="position-relative mt-2 scroll-1">
                                            <div class="col-12">
                                                <div class="row">
                                                    <b-form-checkbox-group
                                                    v-model="selected_personalization_type_itens"
                                                    :options="personalization_type_itens">
                                                    </b-form-checkbox-group>
                                            </div>
                                        </div>
                                        </div>
                                        <button type="button" class="btn btn-info float-right" @click="add">{{ item_index != -1 ? 'Salvar' : 'Adicionar itens' }}</button>
    
                                    </div>
    
    
                                    <div class="card-list-legenda py-2 col-12 mt-4">
                                        <div class="card-body-list-legend d-none d-lg-block">
                                            <div class="col-12 p-0 align-self-center">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12 col-md-4 align-self-center pad-content-list">
                                                            <p class="text-truncate title-legend-list">Tipo de personalização</p>
                                                        </div>
                                                        <div class="col-12 col-md-5 align-self-center text-muted pad-content-list">
                                                            <p class="text-truncate title-legend-list">Itens do tipo</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                    
                                        <div class="position-relative" :id="'item'+index" v-for="(item, index) in trigger.itens" :key="index">
                                        <div class="conn1 position-absolute " />
                                            <div class="card-list ">
                                                <div class="card-body-list" >
                                                    <div class="col-12 col-md-4 p-0 align-self-center">
                                                        {{ item.name }}
                                                    </div>
                                                    <div class="col-12 col-md-5 p-0 align-self-center">
                                                        <div v-for="item in item.personalization_type_itens" @click="checkItemsToShowSubitems($event, index)" :key="item.value" v-html="item.html"></div>
                                                    </div>
                                                    <div class="col-4 col-md-2 col-lg-3 order-3 p-lg-3 p-0 ml-auto text-right align-self-center text-muted">
                                                        <a data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click="triggerItemId(index)" class="btn btn-info text-white rounded-circle btn-circle font-16 mr-2" href="javascript:void(0);"><i class="far fa-plus-square"></i></a>
                                                        <a class="btn btn-warning text-white rounded-circle btn-circle font-16 mr-2" href="javascript:void(0);" @click="edit(index)"><i class="far fa-edit"></i></a>
                                                        <a class="btn btn-danger text-white rounded-circle btn-circle font-16" href="javascript:void(0);" @click="remove(index)"><i class="far fa-trash-alt"></i></a>
                                                    </div>
                                                </div>
                                      
                                       

                                            </div>
                                                               <!-- subitem -->
                                            <div class="ml-5" v-if="item.personalization_types_subitems && item.personalization_types_subitems.length >= 1" >      
                                                <div class="card-list position-relative"  :id="'item1'+index+'item2'+index2" v-for="(item2, index2) in item.personalization_types_subitems" :key="'items2'+index2">
                                                    <div class="conn2 position-absolute" />

                                                    <div class="card-body-list">
                                                        <div class="col-12 col-md-4 p-0 align-self-center">
                                                            {{ item2.personalization_type ? item2.personalization_type.name : item2.name }}
                                                        </div>
                                                        <div class="col-12 col-md-5 p-0 align-self-center">
                                                             <div v-for="item3 in item2.personalization_types_items_subitems" :key="'items3'+item3.value" v-html="item3.html"></div> 
                                                        </div>
                                                        <div class="col-4 col-md-2 col-lg-3 order-3 p-lg-3 p-0 ml-auto text-right align-self-center text-muted">
                                                            <!-- <a class="btn btn-warning text-white rounded-circle btn-circle font-16 mr-2" href="javascript:void(0);" @click="edit(index)"><i class="far fa-edit"></i></a> -->
                                                            <a class="btn btn-danger text-white rounded-circle btn-circle font-16" href="javascript:void(0);" @click="removeSubitem(index, index2)"><i class="far fa-trash-alt"></i></a>
                                                        </div>
                                                     </div>
                                                </div>
                                            </div>
                                        </div>
                                            
                                         
                                            <div class="row justify-content-center p-3" v-if="trigger.itens.length == 0">
                                                Sem itens selecionados.
                                            </div>
                                    </div>
    
                                </div>
                            </div>




                            <!-- Modal -->
                            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-scrollable">
                                    <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="staticBackdropLabel">Adicione sub tipos do tipo de personalização</h5>
                                    </div>
                                    <div class="modal-body">
                                          <div class="col-12 col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <BasePersonalizationTypeBox :personalization_type_subitem_selected="personalization_type_subitem_selected" @input="getSelectedPersonalizationTypeSubitem" @all_personalization_types="getAllPersonalizationTypes" v-model="personalization_type" :multiple="false" />                                     
                                        </div>
                                    </div>
                          
    
                                    <div class="col-12 col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label>Nome do gatilho</label>
                                            <input type="text" id="inputSuccess1" class="form-control" placeholder="Ex.: Selecione o tipo de pedra" v-model="personalization_type_item_name">
                                            <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o nome do kit.</div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-12 col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label>Legenda</label>
                                            <textarea class="form-control" rows="2" placeholder="" v-model="personalization_type_item_subtitle"></textarea>
                                        </div>
                                    </div>
    
                                    <div class="col-12 pb-2">
                                        <label>Selecione os itens do tipo</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text" id="btnGroupAddon2"><i class="fas fa-search"></i></div>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Procurar" v-model="search">
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div data-spy="scroll" data-offset="0" class="position-relative mt-2 scroll-1">
                                            <div class="col-12">
                                                <div class="row">
                                                    <b-form-checkbox-group
                                                    v-model="personalization_type_items_subitems_selected"
                                                    :options="personalization_type_itens">
                                                    </b-form-checkbox-group>
                                            </div>
                                        </div>
                                        </div>
                                        <button type="button" class="btn btn-info float-right" @click="addSubitems">{{ item_index != -1 ? 'Salvar' : 'Adicionar itens' }}</button>
    
                                    </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                                    </div>
                                    </div>
                                </div>
                            </div>




                            <div class="form-actions">
                                <div class="text-right">
                                    <button type="reset" class="btn btn-dark mr-2">Cancelar</button>
                                    <button type="submit" class="btn btn-info">{{ id ? 'Salvar' : 'Cadastrar' }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    import KitConfigService from "@/services/resources/KitConfigService";
    import TriggerService from "@/services/resources/TriggerService";
    const service = TriggerService.build();
    const serviceKitConfig = KitConfigService.build();
    
    export default {
       data(){
           return{
              personalization_type: Number,
               trigger: {
                   name: null,
                   order: null,
                   subtitle: null,
                   type: null,
                   option: null,
                   itens: [],
                   tipologies: []
               },
               pages:[
                    {
                        name: 'dashboard',
                        to: 'Dashboard'
                    },
                    {
                        name: 'novo gatilho',
                        to: 'TriggertNew'
                    }
                ],
                menu:[
                    {
                        name: 'Novo gatilho',
                        to: 'TriggerNew'
                    },
                    {
                        name: 'Gatilhos',
                        to: 'Trigger'
                    }
                ],
                id: null,
                personalization_type_item_name: null,
                personalization_type_item_subtitle: null,
                personalization_type_subitem_name: null,
                personalization_type_subitem_subtitle: null,
                search: '',
                type: null,
                itens_list: [],
                all_personalization_types: [],
                personalization_types: [],
                personalization_type_selected: 0,
                personalization_type_subitem_selected: 0,
                selected_personalization_type_itens: [],
                personalization_type_items_subitems_selected: [],
                selected: null,
                item_index: -1,
                trigger_item_id: 0
           }
       },
       computed:{
        personalization_type_itens(){
            return this.itens_list.filter(elem => {
                return elem.html.toLowerCase().includes(this.search.toLowerCase());
            });
        },
         personalization_type_items_subitems(){
            return this.itens_list.filter(elem => {
                return elem.html.toLowerCase().includes(this.search.toLowerCase());
            });
        },
       },
       methods:{
            getSelectedPersonalizationType(personalization_type) {     
                this.personalization_type_selected = personalization_type 
            },
            getSelectedPersonalizationTypeSubitem(personalization_type) {          
                this.personalization_type_subitem_selected = personalization_type
            },
            getAllPersonalizationTypes(personalization_types) {           
                this.all_personalization_types = personalization_types
                console.log(this.all_personalization_types);
            },
            edit(index){  
                this.type = this.trigger.itens[index].personalization_type.type;
                this.selected = this.trigger.itens[index].id;
                this.personalization_type_selected = this.trigger.itens[index].personalization_type.id
                this.personalization_type_item_name = this.trigger.itens[index].personalization_type_item_name;
                this.personalization_type_item_subtitle = this.trigger.itens[index].personalization_type_item_subtitle;
                this.selected_personalization_type_itens = this.trigger.itens[index].personalization_type_itens.map(elem => elem.value);
                this.item_index = index;
            },
           remove(index){
               for(let i = 0; i < this.itens_list.length; i++){
                console.log(this.trigger);
                   for(let td = 0; td < this.trigger.itens.length; td++){
                    if (index == td) {
                        this.trigger.itens[td].deleted = "1"
                    }
                     //  this.itens_list.splice(j, 1)
                   }
               }
    
               document.getElementById("item"+index).style.display="none"
    
              // this.$delete(this.trigger.itens, index);
           },
                 removeSubitem(index, index2){
             
               for(let i = 0; i < this.itens_list.length; i++){
                console.log(this.trigger);
                   for(let td = 0; td < this.trigger.itens.length; td++){
                    if (index == td) {
                        this.trigger.itens[td].personalization_types_subitems[index2].deleted = 1;
                       // this.trigger.itens[td].deleted = "1"
                    }
                     //  this.itens_list.splice(j, 1)
                   }
               }
    
               document.getElementById("item1"+index+"item2"+index2).style.display="none"
    
              // this.$delete(this.trigger.itens, index);
           },
           add(){
        
               let name = null;
               let personalization_type_itens = [];
               let idSelected = this.personalization_type_selected;
    
               for(let i = 0; i < this.all_personalization_types.length; i++){       
                    if(this.all_personalization_types[i].value == this.personalization_type_selected){  
                        name = this.all_personalization_types[i].html;                
                    }
               }
    
    
               for(let i = 0; i < this.personalization_type_itens.length; i++){
                    for(let j = 0; j < this.selected_personalization_type_itens.length; j++){
                        if(this.personalization_type_itens[i].value == this.selected_personalization_type_itens[j]){
                            personalization_type_itens[personalization_type_itens.length] = this.personalization_type_itens[i]
                        }
                    }
               }


               console.log(personalization_type_itens);
               console.log(this.trigger);
    
               if(!(this.item_index == -1)) {
                    idSelected = this.selected_personalization_type_itens[this.selected_personalization_type_itens.length - 1];
               }
      //  alert(idSelected)
               let data = {
                   id: this.personalization_type_selected,
                   name: name,
                   personalization_type_item_name: this.personalization_type_item_name,
                   personalization_type_item_subtitle: this.personalization_type_item_subtitle,
                   personalization_type_itens: personalization_type_itens
               }
    

               if ( this.trigger.itens[this.item_index] != null && this.trigger.itens[this.item_index].personalization_types_subitems != null ) {
                if (this.trigger.itens[this.item_index].personalization_types_subitems.length >=1){
                    data.personalization_types_subitems =  this.trigger.itens[this.item_index].personalization_types_subitems
                }
               }
    
               if(this.item_index != -1){
                    this.trigger.itens[this.item_index] = data;
                    this.item_index = -1;
               }else{
                    this.trigger.itens.push(data);
               }
               
               this.selected = null;
               this.selected_personalization_type_itens = [];
               this.type = null;
               this.personalization_type_name = null;
               this.personalization_type_subtitle = null; 
               console.log(trigger);
           },
           triggerItemId(id)  {
                this.trigger_item_id = id
            },
         addSubitems(){
               let name = null;
               let personalization_type_items_subitems = [];
               let idSelected = this.personalization_type_subitem_selected;
         
    console.log(this.personalization_type_subitem_selected);
    console.log(idSelected);
               for(let i = 0; i < this.all_personalization_types.length; i++){       
                    if(this.all_personalization_types[i].value == this.personalization_type_subitem_selected){  
                        name = this.all_personalization_types[i].html;                
                    }
               }  
    
        console.log(this.personalization_type_itens);
               for(let i = 0; i < this.personalization_type_itens.length; i++){
                    for(let j = 0; j < this.personalization_type_items_subitems_selected.length; j++){
                        if(this.personalization_type_itens[i].value == this.personalization_type_items_subitems_selected[j]){
                            personalization_type_items_subitems[personalization_type_items_subitems.length] =  this.personalization_type_itens[i]
                           
                       }
                    }
               }              
    
    
        
               console.log(this.trigger);
    
            //    if(!(this.item_index != -1)) {
            //         idSelected = this.personalization_type_items_subitems_selected[this.personalization_type_items_subitems_selected.length - 1];
            //    }

                let ptis = []
               personalization_type_items_subitems.forEach((ele) => {
                console.log(ele);
                    ptis[ptis.length] = {
                        value: ele.value,    
                        html:  ele.html
                    }
                
               });  

               console.log(ptis);
        console.log(idSelected);
        console.log("passei por aqui");
               let data = { 
                    kit_config_id_of_type_personalization_type: idSelected,
                    name: name,
                    subtitle: this.personalization_type_item_subtitle,
                    personalization_type_subitem_name: this.personalization_type_item_name,
                    personalization_type_subitem_subtitle: this.personalization_type_item_subtitle,
                    personalization_types_items_subitems:ptis
               }
    
               console.log(data);

               if(this.trigger.itens[this.trigger_item_id].personalization_types_subitems.length >= 1){
                    this.trigger.itens[this.trigger_item_id].personalization_types_subitems[this.trigger.itens[this.trigger_item_id].personalization_types_subitems.length] = data;
               }else{
                    this.trigger.itens[this.trigger_item_id].personalization_types_subitems = [data]
               }

               console.log( this.trigger.itens[this.trigger_item_id]);
               
               this.selected = null;
               this.personalization_type_items_subitems_selected = [];
               this.type = null;
               this.personalization_type_subitems_name = null;
               this.personalization_type_subitems_subtitle = null; 
               this.$forceUpdate()
               
           },
           save(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                  
                    let trigger = Object.assign({}, this.trigger)
                    console.log( trigger);



                    trigger.itens = trigger.itens.map(element => {
                        console.log(element);
                        console.log(this.trigger);
                        console.log(element.personalization_types_subitems);


                         let pts = []
                         console.log(element);
                         if ( element.personalization_types_subitems != null) {
                        element.personalization_types_subitems.forEach((ele, id) => {
                            console.log(ele);

                                 
                                
                            pts[pts.length] = ele
                                                          pts[id].id_tabela = ele.id

                             console.log(pts);      
                             
                             

                       
                            ele.personalization_types_items_subitems.forEach((ele2, id2) => {
                                if (ele2.personalization_type_item != null) {
                                    pts[id].personalization_types_items_subitems[id2] = { personalization_type_item_subitem_id: ele2.personalization_type_item.id, id_tabela: ele2.valuetabela }
                                } else {
                                     pts[id].personalization_types_items_subitems[id2] = { personalization_type_item_subitem_id: ele2.value, id_tabela: ele2.valuetabela }

                                }
                                
                            })
                        
                           


                     
                        })
                         }
    console.log(pts);

                        return {    
                            id: element.id,
                            deleted: element.deleted,
                            name: element.personalization_type_item_name,
                            personalization_type_items_to_show_subitems: element.personalization_type_items_to_show_subitems,
                            subtitle: element.personalization_type_subitem_subtitle,
                            personalization_type_itens: element.personalization_type_itens.map(elem => elem.value), 
                            personalization_types_subitems: pts
                            //element.personalization_types_subitems
                        };
                    })

                    console.log(trigger.itens);
        console.log(this.trigger);
                    if(this.id){
                        trigger.id = this.id;
    
                        service
                        .update(trigger)
                        .then(resp => {
                            this.$bvToast.toast('Seu gatilho foi atualizado com sucesso!', {
                                title: 'Gatilho atualizado',
                                autoHideDelay: 5000,
                                type: 'success'
                            })
                            this.fetchType();
                            this.fetchTypeItens()
                            this.fetchTrigger();
                        })
                        .catch(err => {
                            this.$bvToast.toast('Um erro inesperado aconteceu no sistema.', {
                                title: 'Erro',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
                    }else{
                        service
                        .create(trigger)
                        .then(resp => {
                            this.$bvToast.toast('Seu gatilho foi criado com sucesso!', {
                                title: 'Gatilho criado',
                                autoHideDelay: 5000,
                                type: 'success'
                            })
                        })
                        .catch(err => {
                            this.$bvToast.toast('Um erro inesperado aconteceu no sistema.', {
                                title: 'Erro',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
                    }
    
              
                }
                this.$forceUpdate()
            });
           },
           
           fetchType(){        
                let data = {    
                    type: 'PERSONALIZATION_TYPE'
                };
    
                if(this.search){
                    data['search'] = this.search;
                }
    
                serviceKitConfig
                .search(data)
                    .then(resp => {
                        this.personalization_type = resp;
                        console.log(this.personalization_type);
                    })
                    .catch(err => {
                    console.log(err)
                })
           },
           fetchTypeItens(){        
                let data = {    
                    type: 'PERSONALIZATION_TYPE_ITEM'
                };
    
                if(this.search){
                    data['search'] = this.search;
                }
    
                serviceKitConfig
                .search(data)
                    .then(resp => {
                        let itens = []
                    //  setTimeout(function(){
                            resp.forEach(element => {
                                itens[itens.length] = {
                                    value: element.id,
                                    html: element.identification_name,
                                    disabled: false
                                }
                            })
                            this.itens_list = itens 
                        
                    })
                    .catch(err => {
                    console.log(err)
                })
           },
           fetchTrigger(){
               if(!this.id){
                   return;
               }
    
               let data = {
                   id: this.id
               };
    
               service
                .read(data)
                .then(resp => {
                    this.trigger = resp;
                    this.trigger.tipologies = resp.tipologies.map(elem => elem.tipology_id);
                    this.trigger.itens = resp.itens.map(elem => {

                        let personalization_type_items_to_show_subitems = []
                        
                        if(elem.personalization_type_items_to_show_subitems != null){
                           personalization_type_items_to_show_subitems = elem.personalization_type_items_to_show_subitems.split("-")
                        }

                        let subitens = []
                        if(elem.personalization_types_subitems != null){
                            elem.personalization_types_subitems.forEach((eles) => {
                                
                            });
                        }

                        let data = {
                            id: elem.personalization_type.id,
                            personalization_type: elem.personalization_type,
                            name: elem.personalization_type.name,
                            deleted: elem.personalization_type.deleted,
                            personalization_type_item_name: elem.name,
                            personalization_type_item_subtitle: elem.subtitle,
                            personalization_type_items_to_show_subitems: personalization_type_items_to_show_subitems ,
                            personalization_type_itens: elem.personalization_type_itens.map(element => {
                            

                                let filters = personalization_type_items_to_show_subitems.filter((filter) => {
                                    if (filter ==  element.personalization_type_item.id) {
                                        return filter
                                    }
                                })

                                 let pts  = []
                                 console.log( elem.personalization_type.id);
                                 pts = elem.personalization_types_subitems.filter((ele3) => {
                                    console.log(ele3);
                                    console.log(elem.personalization_type.id);
                                     console.log(ele3.father_personalization_types_id);
                                    if (ele3.father_personalization_types_id ==  elem.personalization_type.id) {
                                        return ele3
                                    }
                                })
                                console.log(elem);
                    console.log(pts);

                    

                        


                                if (pts.length >= 1) {

                                    

                                    if(filters.length >= 1){
                                        return {
                                            value: element.personalization_type_item.id,    
                                            html: '<b><input type="checkbox" id="'+element.personalization_type_item.id+'"  class="mr-3" checked />' + element.personalization_type_item.identification_name + '</b> - R$ ' + element.personalization_type_item.price
                                        };
                                    } else {
                                        return {
                                            value: element.personalization_type_item.id,    
                                            html: '<b><input id="'+element.personalization_type_item.id+'" type="checkbox" class="mr-3" />' + element.personalization_type_item.identification_name + '</b> - R$ ' + element.personalization_type_item.price
                                        };
                                    }
                                    
                                } else {
                                    return {
                                        value: element.personalization_type_item.id,    
                                        html: '<b>' +  element.personalization_type_item.identification_name + '</b> - R$ ' + element.personalization_type_item.price
                                    };
                                }
                            }),
                            personalization_types_subitems: elem.personalization_types_subitems.filter((re) => { if (re.father_personalization_types_id == elem.kit_config_id) { return re }}) ?? [],
                            personalization_types_items_subitems: elem.personalization_types_subitems.length > 0 ? elem.personalization_types_subitems.map((element1) => { 
                                console.log(element1);
                            element1.personalization_types_items_subitems.map(element => {
                                console.log( element);
                               return  { 
                                    value: element.personalization_type_item.id,    
                                    valuetabela: element.id,
                                    html: '<b>' + element.personalization_type_item.identification_name + '</b> - R$ ' + element.personalization_type_item.price
                                };
                                console.log(a);
                            })
                            }) : null,
                        } 

                        
                        let pts = []
                        data.personalization_types_subitems.forEach((ele, id) => {
                            console.log(ele);
                            pts[pts.length] = ele
                             console.log(pts);
                        //     pts = pts.filter((ele3) => {
                        //         if (ele3.father_personalization_types_id == data.id) {
                        //             return ele3
                        //         }
                        // })
                        // pts = pts.filter((ele3) => {
                        //             if (ele3.father_personalization_types_id ==  elem.personalization_type.id) {
                        //                 return ele3
                        //             }
                        //         })

                       if (pts.length >=1) {
                            ele.personalization_types_items_subitems.forEach((ele2, id2) => {
                                pts[id].personalization_types_items_subitems[id2] = {
                                     value: ele2.personalization_type_item.id,    
                                     valuetabela: ele2.id,
                                    html: '<b>' + ele2.personalization_type_item.identification_name + '</b> - R$ ' + ele2.personalization_type_item.price
                                }
                            })
                            
                       }

                       
                        
                         data.personalization_types_subitems = pts

                         console.log(data);  
                     
                       })

                        console.log(data);
                        
                        return data;
    
                    })
    
                    
                })
                .catch(err => {
                    console.log(err)
                })
           },
           checkItemsToShowSubitems(eve, id) {
                console.log(eve.srcElement.checked);
                console.log(this.trigger.itens[id]);
                if(eve.srcElement.checked == true){
                    this.trigger.itens[id].personalization_type_items_to_show_subitems[this.trigger.itens[id].personalization_type_items_to_show_subitems.length] = eve.srcElement.id
                    console.log(id);
                    console.log(eve.srcElement.id);
                    console.log( this.trigger.itens[id]);
                }else{
                    let index =  this.trigger.itens[id].personalization_type_items_to_show_subitems.indexOf(eve.srcElement.id);
                    console.log(index);
                    if (index != -1) {
                        this.trigger.itens[id].personalization_type_items_to_show_subitems.splice(index, 1);
                    }
                }
           }
          
    
       },
       mounted(){
           this.id = this.$route.params.id;
           this.fetchType();
           this.fetchTypeItens()
           this.fetchTrigger();
       }
    }

    </script>

    <style>
        .conn1 {
        width: 3px;
        background: #02b475;
        height: 100%;
        display: block;
        position: absolute;
        left: 15px;
        top: 0;
        border-bottom : 9px solid red;

    }
      .conn2 {
          /*  width: 49px;
            background: #02b475;
            height: 5px;
            display: block;
            position: absolute;
            left: 15px;
            top: 189px;*/


/*
    width: 5px;
    background: #02b475;
    height: 156px;
    display: block;
    position: absolute;
    left: -32px;
    top: -101px;
    z-index: -1;
*/


                width: 33px;
    background: #02b475;
    
    height: 3px;
    display: block;
    position: absolute;
    left: -32px;
    z-index: 0;
    top: 50px;
        }
    </style>